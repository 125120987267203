"use client";
import { getRedirectResult, type UserCredential } from 'firebase/auth'
import { googleAuth } from '@llamacloud/auth/client'

export const redirectResultPromise: Promise<UserCredential | null> =
  typeof window !== "undefined" && googleAuth
    ? getRedirectResult(googleAuth)
    : Promise.resolve(null);

export let resolvedPromise = false;
export const resolvedPromiseFn = () => {
  resolvedPromise = true;
};